<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <form
      class="modal-card"
      @submit.prevent="actionAddPress"
    >
      <header class="modal-card-head">
        <p class="modal-card-title has-text-centered">Agregar Publicación</p>
        <button class="delete" aria-label="close" type="reset" @click="close()"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-12">
            <div class="field">
              <div class="control">
                <label class="label">Nombre</label>
                <input
                  class="input"
                  type="text"
                  placeholder="Nombre"
                  v-model="name"
                  name="title"
                  v-validate="'required'"
                  :class="{'is-danger': vverrors.has('title')}"
                >
              </div>
            </div>
            <div class="field">
              <div class="control">
                <label class="label">Url</label>
                <input
                  class="input"
                  type="text"
                  placeholder="Url"
                  v-model="url"
                  name="url"
                  v-validate="'required'"
                  :class="{'is-danger': vverrors.has('url')}"
                >
              </div>
            </div>
            <div class="field">
              <div class="control">
                <div class="file has-name is-boxed is-fullwidth">
                  <label class="file-label full-width">
                    <input required
                      class="file-input"
                      type="file"
                      name="image"
                      @change="uploadFile"
                      data-vv-as="imagen"
                      v-validate="'required|ext:jpeg,jpg,png'"
                      accept=".jpeg,.jpg,.png"
                    >
                    <span class="file-cta">
                      <template v-if="!previewImage">
                        <span class="file-label has-text-centered">
                          Agregar imagen
                        </span>
                      </template>
                      <div class="content__image" v-else>
                        <img :src="previewImage" class="image__uploading">
                      </div>
                    </span>
                  </label>
                </div>
                <p class="help is-danger"> {{vverrors.first('image')}} </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          :disabled="loading"
          class="button modal-cancel-button"
          type="reset"
          @click="close"
        >
          Cancelar
        </button>
        <button
          class="button button-givu"
          :class="{'is-loading': loading}"
          :disabled="loading"
          type="submit"
        >
          Guardar
        </button>
      </footer>
    </form>
  </div>
</template>

<script>
export default {
  name: 'ModalAddPress',
  inject: ['$validator'],
  data () {
    return {
      name: null,
      image: null,
      url: null,
      previewImage: null
    }
  },
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    action: {
      type: Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    uploadFile (event) {
      if (event.target.files[0]) {
        let uploadFile = event.target.files[0]
        if (uploadFile.size > 1100000) {
          this.addTemporalNotification({ type: 'danger', message: 'El peso de la imagen no puede exceder 1 MB' })
        } else {
          const [ file ] = event.target.files
          this[event.target.name] = { key: event.target.name, file }
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onload = e => {
            this.previewImage = e.target.result
          }
        }
      }
    },
    async actionAddPress () {
      if (await this.$validator.validateAll()) {
        const data = {
          title: this.name,
          description: null,
          image: this.image,
          url: this.url
        }
        await this.action(data)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .optional {
    font-size:10px;
  }
  .group-field {
    margin-top: 1px;
    margin-bottom: 1px;
  }
</style>
